<template>
  <div class="ml-0">
    <div class="grid m-0 justify-content-center">
      <div class="flex col-12 justify-content-end align-items-center my-3">
        <Button
          class="m-1"
          label="Close Ticket(s)"
          icon="pi pi-times-circle"
          outlined
          v-show="checkedTickets.length"
          @click="onCloseSelectedTickets"
        />
        <Button
          type="button"
          icon="pi pi-filter-slash"
          v-tooltip="'Clear All Filters'"
          class="split-sales-order-btn"
          outlined
          @click="clearFilter"
        />
        <Button
          data-test="newTicket"
          class="split-sales-order-btn"
          label="New Ticket"
          icon="pi pi-plus"
          iconPos="left"
          @click="
            () => {
              this.showQuickTicketDialog = true;
            }
          "
        />
        <Button
          class="split-sales-order-btn"
          label="Print/Export"
          icon="pi pi-print"
          @click="printTable"
        ></Button>
      </div>
    </div>
    <Card>
      <template #content>
        <LoadingSpinner :loading="isLoadingTickets" />
        <!-- BEGIN WRAPPER DIV -->
        <div v-if="!isLoadingTickets">
          <!-- BEGIN TIME ENTRY VIEW FIELDS -->
          <DataTable
            v-model:selection="checkedTickets"
            :value="formatedTickets"
            v-model:expandedRows="expandedRows"
            :paginator="true"
            :rows="10"
            filterDisplay="menu"
            :row-class="
              () => {
                return 'cursor-pointer';
              }
            "
            v-model:filters="filters"
            @rowExpand="onRowExpand"
            @row-click="rowClicked"
            @filter="handleFilter"
            :sortField="sort.sortBy"
            :sortOrder="sort.sortOrder"
            @sort="sortTickets($event)"
            class="p-datatable-sm table-no-select-all"
            :rowsPerPageOptions="[10, 25, 50]"
          >
            <template #empty> No Tickets to display. </template>
            <Column :expander="true" />
            <Column
              field="id"
              header="ID"
              :showFilterMatchModes="false"
              :showFilterOperator="false"
              sortable
            >
              <template #filter>
                <InputText
                  placeholder="Search By Ticket ID"
                  v-model="selectedFilterId"
                />
              </template>
              <template #filterclear>
                <Button
                  type="button"
                  label="Clear"
                  @click="($event) => clearTicketSearchBar()"
                />
              </template>
              <template #filterapply>
                <Button
                  type="button"
                  label="Apply"
                  @click="fetchFilteredTickets(false)"
                />
              </template>
            </Column>
            <Column field="title" header="Title"></Column>
            <Column
              v-if="currentView !== 'customers'"
              field="cust_name"
              header="Customer"
              :showFilterMatchModes="false"
              :showFilterOperator="false"
              sortable
            >
              <template #filter>
                <MultiselectWithButton
                  :value="selectedFilterCusts"
                  :options="searchedFilterCusts"
                  optionLabel="name"
                  placeHolder="Enter Customer ID or Name"
                  :filter="true"
                  filterMatchMode="startsWith"
                  :style="{ width: '200px' }"
                  btnLabel="Search"
                  :maxSelectedLabels="2"
                  :filterFields="['name', 'cust_id']"
                  @btnCallback="filterCustomers()"
                  @updateFilterValue="
                    (filterValue) => (this.selectedFilterCusts = filterValue)
                  "
                  @filter="handleCustomerSelectFilter($event)"
                />
              </template>
              <template #filterclear>
                <Button
                  type="button"
                  label="Clear"
                  @click="($event) => clearCustonmerSearchBar()"
                />
              </template>
              <template #filterapply>
                <Button
                  type="button"
                  label="Search"
                  @click="($event) => filterCustomers()"
                />
              </template>
            </Column>
            <Column
              field="assigned_to"
              header="Assignee"
              :showFilterMatchModes="false"
              :showFilterOperator="false"
              sortable
            >
              <template #filter>
                <MultiselectWithButton
                  :value="selectedFilterAssignees"
                  :options="getUsers"
                  optionValue="user_id"
                  optionLabel="user_name"
                  dataKey="user_id"
                  placeHolder="Filter By Assignee"
                  :filter="true"
                  filterMatchMode="startsWith"
                  :style="{ width: '200px' }"
                  btnLabel="Search"
                  @btnCallback="fetchFilteredTickets(false)"
                  @updateFilterValue="
                    (filterValue) =>
                      (this.selectedFilterAssignees = filterValue)
                  "
                />
              </template>
              <template #filterclear>
                <Button
                  type="button"
                  label="Clear"
                  @click="($event) => clearAssignee()"
                />
              </template>
              <template #filterapply>
                <Button
                  type="button"
                  label="Search"
                  @click="($event) => fetchFilteredTickets(false)"
                />
              </template>
            </Column>
            <Column
              field="type"
              header="Type"
              :showFilterMatchModes="false"
              :showFilterOperator="false"
              sortable
            >
              <template #filter>
                <MultiselectWithButton
                  :value="selectedFilterTypes"
                  :options="getBillingTypes"
                  optionValue="billing_type"
                  optionLabel="billing_type"
                  placeHolder="Filter by Type"
                  btnLabel="Search"
                  @btnCallback="fetchFilteredTickets(false)"
                  @updateFilterValue="
                    (filterValue) => (this.selectedFilterTypes = filterValue)
                  "
                />
              </template>
              <template #filterclear>
                <Button
                  type="button"
                  label="Clear"
                  @click="($event) => clearTypes()"
                />
              </template>
              <template #filterapply>
                <Button
                  type="button"
                  label="Search"
                  @click="($event) => fetchFilteredTickets(false)"
                />
              </template>
            </Column>
            <Column
              field="formated_date"
              header="Date Created"
              :showFilterMatchModes="false"
              :showFilterOperator="false"
              sortable
              bodyStyle="text-align:right"
            >
              <template #body="{ data }">
                {{ data.date ? formatDate(data.formated_date) : "" }}
              </template>
            </Column>
            <Column
              field="closed"
              header="Status"
              :showFilterMatchModes="false"
              :showFilterOperator="false"
            >
              <template #body="slotProps">
                {{ displayStatus(slotProps.data.closed) }}
              </template>
              <template #filter>
                <Dropdown
                  v-model="selectedFilterStatus"
                  :options="status"
                  optionLabel="status"
                  optionValue="initial"
                  placeholder="Filter By Status"
                  filterMatchMode="startsWith"
                  @change="fetchFilteredTickets(false)"
                  :showClear="true"
                  :style="{ width: '150px' }"
                />
              </template>
              <template #filterclear>
                <Button
                  type="button"
                  label="Clear"
                  @click="($event) => clearStatus()"
                />
              </template>
            </Column>
            <Column selectionMode="multiple"></Column>
            <template #expansion="slotProps">
              <QuickTicket
                :active="active"
                :ticket="slotProps.data"
                :isEditingTicket="true"
                :bill="billingInfo"
                :first="first"
                :rows="rows"
                :showCancelButton="false"
                @onTicketBilling="
                  (event) => {
                    if (event.success) this.getBillRecords();
                  }
                "
                @onStatusUpdate="
                  (event) => {
                    if (
                      event.success &&
                      this.selectedFilterStatus !== null &&
                      this.selectedFilterStatus
                    )
                      fetchFilteredTickets(false);
                  }
                "
                @onSave="
                  (event) => {
                    if (event.success) fetchFilteredTickets(false);
                  }
                "
              />
              <div class="grid">
                <div class="col-12 justify-content-center ml-2">
                  <i class="pi pi-clock"></i>
                  <span
                    v-if="!this.loadingBill"
                    class="col-12 mr-1 link"
                    @click="viewTimeLog"
                    >Total Hours: {{ TotalHours.toFixed(2) }}</span
                  >
                  <span v-if="this.loadingBill" class="col-12 mr-1"
                    >Total Hours:
                    <span
                      class="pi pi-spin pi-spinner"
                      style="font-size: 1rem"
                    ></span
                  ></span>
                </div>
              </div>
              <div class="col-12 mt-2">
                <div class="flex justify-content-center"></div>
                <DataTable
                  :value="this.billing.bill_items"
                  responsiveLayout="stack"
                  breakpoint="960px"
                  :hidden="this.timeLogHidden"
                >
                  <template #empty> No Bill Records to display. </template>
                  <Column field="bill_id" header="Bill ID" sortable />
                  <Column field="date" header="Date" sortable>
                    <template #body="{ data }">
                      {{ formatDate(data.date) }}
                    </template>
                  </Column>
                  <Column field="user" header="User" />
                  <Column
                    field="desc"
                    header="Description"
                    style="line-break: anywhere; word-break: keep-all"
                  >
                    <template #body="{ data }">
                      <span class="line-break">
                        {{ formatCharToLineBreak(data.desc) }}
                      </span>
                    </template>
                  </Column>
                  <Column field="hours" header="Hours" />
                  <Column
                    field="notes"
                    header="Note"
                    style="line-break: anywhere; word-break: keep-all"
                  >
                    <template #body="{ data }">
                      <span class="line-break">
                        {{ formatCharToLineBreak(data.notes) }}
                      </span>
                    </template>
                  </Column>
                </DataTable>
              </div>
            </template>
          </DataTable>
          <!-- END OF TIME ENTRY VIEW FIELDS -->
        </div>
      </template>
    </Card>
    <!-- END WRAPPER DIV -->

    <ConfirmDialog></ConfirmDialog>
    <!-- ADD QUICK TICKET DIALOG -->
    <QuickTicketDialog
      :show="showQuickTicketDialog"
      @hide="
        (isHidden) => {
          this.showQuickTicketDialog = isHidden;
          this.newTicket = null;
          this.billingInfo = null;
        }
      "
    >
      <template #content>
        <QuickTicket
          @hide="
            (isHidden) => {
              this.showQuickTicketDialog = isHidden;
            }
          "
          @onSave="
            (event) => {
              if (event.success) this.showQuickTicketDialog = isHidden;
              this.pushTicketsId({ index: this.index, id: event.ticket.id });
              this.fetchFilteredTickets(false);
            }
          "
          :first="first"
          :rows="rows"
          :isEditingTicket="isEditingTicket"
          :customer="
            filters.cust_name.value.length === 1 && currentView === 'customers'
              ? selectedFilterCusts[0].cust_id
              : null
          "
        />
      </template>
    </QuickTicketDialog>
    <!-- END QUICK TICKET DIALOG -->
  </div>
  <Footer />
</template>

<script>
import { defineComponent } from "vue";
import Card from "primevue/card";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import Tooltip from "primevue/tooltip";
import useVuelidate from "@vuelidate/core";
import { required, between } from "@vuelidate/validators";
import Footer from "@/components/Footer.vue";
import ConfirmDialog from "primevue/confirmdialog";
import MultiselectWithButton from "@/components/UI/MultiselectWithButton.vue";
import QuickTicket from "@/components/Billing/QuickTicket.vue";
import TicketService from "@/services/TicketService";
import CustomerService from "@/services/CustomerService";
import BatchService from "@/services/BatchService";
import { mapActions, mapGetters } from "vuex";
import QuickTicketDialog from "./Billing/QuickTicketDialog.vue";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import _debounce from "lodash/debounce";
import _uniq from "lodash/uniq";
import Utils from "@/utility/utils";

export default defineComponent({
  components: {
    InputText,
    Button,
    DataTable,
    Column,
    Dropdown,
    LoadingSpinner,
    Footer,
    ConfirmDialog,
    QuickTicket,
    QuickTicketDialog,
    Card,
    MultiselectWithButton,
  },
  created() {
    if (this.index !== undefined && this.index !== null) {
      this.filters.cust_name.value = [this.getCurrentCustomer.name];
      this.selectedFilterCusts = [this.getCurrentCustomer];
    } else {
      this.filters.cust_name.value = [];
      this.selectedFilterCusts = [];
    }
    this.billingInfo.date = new Date();
    this.newTicket.date = new Date();
    if (this.getLength >= this.rows) {
      this.first = this.getPage * this.rows;
    }
    const CONTROLS = [
      {
        Client: this.getClient,
        id: "BILL",
        filename: "CONTROL",
        control: "BILL.CONTROL",
        procedure: "BILL.CONTROL",
        getter: this.getBillingTypes,
      },
    ];
    CONTROLS.forEach((element) => {
      if (!element.getter || element.getter.length === 0) {
        this.fetchControls(element);
      }
    });
    let currentUserDetails = this.getUser;
    this.currentUser = currentUserDetails.user_id;
    this.filters.assigned_to.value = [];
    this.selectedFilterAssignees = [];
    if (this.currentView !== "customers") {
      this.selectedFilterStatus = "O";
      this.filters.assigned_to.value.push(this.currentUser);
      this.selectedFilterAssignees.push(this.currentUser);
    }
    this.billingInfo.user = currentUserDetails.user_id;
    this.newTicket.assigned_to = currentUserDetails.user_id;
    this.fetchFilteredTickets(false);

    if (this.getParts?.length === 0) {
      this.fetchParts({
        fieldnames: "part_no category wrap_desc",
      });
    }
  },
  directives: {
    tooltip: Tooltip,
  },
  updated() {
    // SO THAT THE REQUIRED ERROR DOES NOT SHOW IN THE ACCORDION FORM AFTER DIALOG IS CLOSED
    if (!this.showAddTimeEntryDialog && this.submitted) this.submitted = false;
    if (!this.showAddTicketDialog) this.isEditing = false;
    if (this.showAddTicketDialog && this.newTicket.cust != "") {
      this.custService
        .getAllCustomers({
          selection: this.newTicket.cust,
          Client: this.getClient,
        })
        .then((response) => {
          if (response.cust_items.length > 0) {
            const cust = response.cust_items[0];
            this.newTicketCust = cust.name;
            this.setValidParts(cust.type);
          }
        });
    }
  },
  computed: {
    ...mapGetters({
      getLength: "billing/getLength",
      getBillingTypes: "billing/getBillingTypeItems",
      getUsers: "users/getUsers",
      getTickets: "billing/getTickets",
      getParts: "billing/getParts",
      getCustTypeItems: "billing/getCustTypeItems",
      getRange: "billing/getRange",
      getPage: "billing/getPage",
      getUser: "session/getUser",
      getClient: "session/getClient",
      getCurrentCustomer: "customerInquiry/getCurrentCustomer",
      getCurrentCustTabIndex: "customerInquiry/getCurrentCustTabIndex",
      getTicketIds: "customerInquiry/getTicketIds",
    }),
    isTicketBillable() {
      let tickets = this.getTickets.slice(this.first, this.first + this.rows);
      let activeTicket = tickets[this.active];
      let billingTypes = this.getBillingTypes;
      return billingTypes.find(
        (element) => element.billing_type === activeTicket?.type
      )?.billable;
    },
    formatedTickets() {
      const formatedTicketList = JSON.parse(JSON.stringify(this.ticketArray));
      formatedTicketList.forEach((ticket) => {
        ticket.formated_date = ticket.date ? new Date(ticket.date) : "";
      });
      return formatedTicketList;
    },
  },
  props: {
    currentView: {
      type: String,
      required: false,
      default: "",
    },
    index: {
      type: [Number, Object],
      required: false,
      default: null,
    },
  },
  data() {
    return {
      printExportItems: [],
      checkedTickets: [],
      selectedFilterCusts: [],
      searchedFilterCusts: [],
      selectedFilterId: "",
      selectedFilterAssignees: [],
      selectedFilterTypes: [],
      selectedFilterStatus: null,
      activeTicketIndex: -1,
      showQuickTicketDialog: false,
      current_date: new Date(),
      newTicketCust: "",
      newQuickTicket: null,
      newTimeEntryCust: "",
      currentIndex: null,
      ticket: [],
      billing: [],
      active: null,
      selectedStatus: null,
      selectedCustomer: null,
      selectedTypes: null,
      setStatus: "",
      selectedAssignee: null,
      selectedTicketID: "",
      currentTicketID: 0,
      TotalHours: 0,
      windowSize: window.innerHeight,
      showAddTicketDialog: false,
      showAddTimeEntryDialog: false,
      showTimeLogDialog: false,
      isLoadingSubmit: false,
      isLoadingTickets: false,
      timeLogHidden: true,
      isAttachmentLogHidden: true,
      currentUser: null,
      submitted: false,
      billable: false,
      billingInfo: Object(),
      newTicket: Object(),
      ticketsPerPage: 5,
      first: 0,
      option: "",
      page: 1,
      sort: {
        sortBy: "",
        sortOrder: 0,
      },
      loadingTicketRecords: [],
      ticketsToView: [],
      pagination: [
        { num: 5 },
        { num: 10 },
        { num: 15 },
        { num: 20 },
        { num: 25 },
      ],
      columnStyle: "col-12 md:col-1 md:flex-grow-1 lg:col-1 lg:flex-grow-1",
      status: [
        { status: "Open", initial: "O" },
        { status: "Closed", initial: "C" },
      ],
      statusOptions: [
        {
          label: "",
          command: () => {
            this.updateCloseTicket();
          },
        },
      ],
      parts: null,
      isLoadingTicket: false,
      isLoadingTimeEntry: false,
      loadingBill: false,
      ticketArray: null,
      rows: 10,
      isEditing: false,
      ticketService: new TicketService(process.env.VUE_APP_ABSTRACTION_API),
      batchService: new BatchService(process.env.VUE_APP_ABSTRACTION_API),
      custService: new CustomerService(),
      expandedRows: [],
      totalRecords: 0,
      maxExportRows: 1000,
      rangeStart: 1,
      rangeEnd: 10,
      allExportColumns: [
        { field: "id", header: "ID", input: true },
        { field: "closed", header: "Status", input: true },
        { field: "title", header: "Title", input: true },
        { field: "cust_name", header: "Customer", input: true },
        { field: "date", header: "Date", input: true },
        { field: "assigned_to", header: "Assignee", input: true },
        { field: "type", header: "Type", input: true },
      ],
      dynamicColumns: [
        { field: "id", header: "ID", input: true },
        { field: "closed", header: "Status", input: true },
        { field: "title", header: "Title", input: true },
        { field: "cust_name", header: "Customer", input: true },
        { field: "date", header: "Date", input: true },
        { field: "assigned_to", header: "Assignee", input: true },
        { field: "type", header: "Type", input: true },
      ],
      filters: {
        cust_name: {
          value: [],
          matchMode: FilterMatchMode.IN,
        },
        type: {
          value: [],
          matchMode: FilterMatchMode.IN,
        },
        assigned_to: {
          value: [],
          matchMode: FilterMatchMode.IN,
        },
        id: {
          value: "",
          matchMode: FilterMatchMode.EQUALS,
        },
        closed: {
          value: [""],
          matchMode: FilterMatchMode.IN,
        },
      },
    };
  },
  watch: {
    getCurrentCustomer() {
      if (
        this.getCurrentCustTabIndex == this.index &&
        this.getCurrentCustomer
      ) {
        this.filters.cust_name.value = [this.getCurrentCustomer.name];
        this.selectedFilterCusts = [this.getCurrentCustomer];
        this.filterCustomers();
      }
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      newTicket: {
        type: { required },
      },
      billingInfo: {
        hours: { required, between: between(0, 24) },
      },
    };
  },
  methods: {
    ...mapActions({
      fetchControls: "billing/getControls",
      fetchParts: "billing/fetchParts",
      updateTicket: "billing/updateTicket",
      fetchTickets: "billing/getTickets",
      addNotification: "notification/add",
      addTicket: "billing/addTicket",
      postBilling: "billing/postBillingInfo",
      fetchBills: "billing/getBills",
      setItemsPerPage: "billing/setItemsPerPage",
      nextPage: "billing/nextPage",
      updateCurrentIndex: "billing/updateCurrentIndex",
      pushTicketsId: "customerInquiry/pushTicketsId",
      clearPrintData: "printableDatatable/clearData",
      setPrintData: "printableDatatable/setData",
      setPrintDefaultColumns: "printableDatatable/setDefaultColumns",
      setPrintAvaialbleColumns: "printableDatatable/setAvaialbleColumns",
    }),
    async printTable() {
      //block a print with no criteria applied
      if(this.totalRecords === 0) {
        const notification = {
          message: `No records to print or export.`,
          type: "error",
        };
        this.addNotification(notification);
        return;
      }
      if (this.totalRecords > this.maxExportRows) {
        const notification = {
          message: `Please filter the results before printing.`,
          type: "error",
        };
        this.addNotification(notification);
      } else {
        this.clearPrintData();
        // get full dataset for the current criteria
        await this.fetchTicketToDisplay(false);
        this.setPrintData(this.printExportItems);
        this.setPrintDefaultColumns(this.dynamicColumns);
        this.setPrintAvaialbleColumns(this.allExportColumns);

        window.open(
          "/printable-view?print=1&showSelection=1",
          "_blank"
        );
      }
    },
    filterCustomers() {
      this.filters.cust_name.value = this.selectedFilterCusts.map((cust) => {
        return cust.name;
      });
      this.searchedFilterCusts = [...this.selectedFilterCusts];
      this.fetchFilteredTickets(false);
    },
    handleCustomerSelectFilter: _debounce(async function (event) {
      const customers = await this.custService.getAllCustomers({
        selection: event.value,
      });
      this.searchedFilterCusts = _uniq([
        ...customers.cust_items.sort((a, b) => a.name.localeCompare(b.name)),
        ...this.selectedFilterCusts,
      ]);
    }, 1000),
    sortTickets(event) {
      this.sort = {
        sortOrder: event.sortOrder,
        sortBy: event.sortField,
      };
      this.fetchFilteredTickets(false);
    },
    formatDate(date) {
      return Utils.formatDate(date);
    },
    formatCharToLineBreak(text) {
      return Utils.formatCharToLineBreak(text);
    },
    onRowExpand(event) {
      this.active = this.getTickets.findIndex(
        (ticket) => ticket.id === event.data.id
      );
      this.getBillRecords();
      if (this.expandedRows.length > 1) {
        this.expandedRows.shift();
      }
    },
    rowClicked(event) {
      if (event.originalEvent.target.tagName === "TD") {
        const found = this.expandedRows.find(
          (ticket) => ticket.id === event.data.id
        );
        if (found) {
          // Not sure why this works
          this.expandedRows.pop(event.index);
        } else {
          this.expandedRows.push(event.data);
          this.onRowExpand(event);
        }
      }
    },
    resize(event) {
      this.windowSize = window.innerWidth;
    },
    SortArray(x, y) {
      if (x.part_no < y.part_no) {
        return -1;
      }
      if (x.part_no > y.part_no) {
        return 1;
      }
      return 0;
    },
    deepCopy() {
      this.ticketArray = JSON.parse(JSON.stringify(this.getTickets));
    },
    setValidParts(custType) {
      if (custType === undefined || custType === null) {
        this.parts = this.getParts;
        return;
      }
      let custItems = [];
      custItems = this.getCustTypeItems.filter((custitem) => {
        return custitem.cust_type === custType;
      });
      if (custItems !== null && custItems !== undefined && custItems !== []) {
        let partCats = [];
        custItems.forEach((item) => {
          partCats.push(item.parts_cat);
        });
        if (partCats.length !== 0) {
          this.parts = [];
          partCats.forEach((category) => {
            this.parts.push(
              ...this.getParts.filter((part) => {
                return part.category === category;
              })
            );
          });
        } else {
          this.parts = this.getParts;
        }
      } else {
        this.parts = this.getParts;
      }
    },
    filterCust(element) {
      this.isLoadingTickets = true;
      let rangeStart = this.rangeStart;
      let rangeEnd = this.rangeEnd;

      rangeStart = 1;
      rangeEnd = this.maxExportRows;

      if (this.currentView === "customers" && !this.selectedTicketID) {
        this.getTicketIds(this.index).forEach((id) => {
          this.selectedTicketID += id + " ";
        });
      }
      this.fetchTickets({
        cust: this.selectedCustomer.cust_id,
        ids: this.selectedTicketID,
        status: this.selectedStatus,
        assignees: this.getAssigneeFilter(),
        correls: "cust_name",
        types: this.getTypesFilter(),
      })
        .then((resp) => {
          this.ticketArray = resp.data;
          this.printExportItems = resp.data;
          
          this.totalRecords = resp.data.length;
        })
        .finally(() => {
          this.isLoadingTickets = false;
        });
    },
    setBillable(event) {
      this.getBillingTypes.map((element) => {
        if (event == element.billing_type) {
          this.billable = element.billable;
        }
      });
    },
    viewTimeLog() {
      this.timeLogHidden = this.timeLogHidden !== true;
    },
    fetchFilteredTickets(addTicket) {
      this.active = null;
      this.isLoadingTickets = true;
      let ticketIds = "";
      let rangeStart = this.rangeStart;
      let rangeEnd = this.rangeEnd;

      rangeStart = 1;
      rangeEnd = this.maxExportRows;

      if (this.currentView === "customers") {
        if (this.selectedFilterId) {
          if (!this.getTicketIds(this.index).includes(this.selectedFilterId)) {
            this.isLoadingTickets = false;
            return;
          }
        } else {
          this.getTicketIds(this.index).forEach((id) => {
            ticketIds += id + " ";
          });
        }
      }
      this.filters.id.value = this.selectedFilterId;
      this.filters.assigned_to.value = [...this.selectedFilterAssignees];
      this.filters.type.value = [...this.selectedFilterTypes];
      switch (this.selectedFilterStatus) {
        case "O":
          this.filters.closed.value = ["N", ""];
          break;
        case "C":
          this.filters.closed.value = ["Y"];
          break;
        default:
          this.filters.closed.value = [];
          break;
      }

      const sort = {
        sortOrder: this.sort.sortOrder === -1 ? "DEC" : "",
        sortBy: "",
      };
      switch (this.sort.sortBy) {
        case "id":
          sort.sortBy = "id";
          break;
        case "cust_name":
          sort.sortBy = "cust";
          break;
        case "assigned_to":
          sort.sortBy = "assigned.to";
          break;
        case "type":
          sort.sortBy = "type";
          break;
        case "formated_date":
          sort.sortBy = "date";
          break;
      }

      this.fetchTickets({
        custs: this.getCustomersFilter(),
        ids: ticketIds || this.filters.id.value,
        status: this.selectedFilterStatus,
        addTicket: addTicket,
        assignees: this.getAssigneeFilter(),
        types: this.getTypesFilter(),
        sortBy: sort.sortBy,
        sortOrder: sort.sortOrder,
        correls: "cust_name",
      })
        .then((resp) => {
          this.ticketArray = resp.data;
          this.printExportItems = resp.data.map((item) => {
            return {
              id: item.id,
              closed: this.displayStatus(item.closed),
              title: item.title,
              cust_name: item.cust_name,
              date: item.date,
              assigned_to: item.assigned_to,
              type: item.type,
            };
          });
          this.totalRecords = resp.data.length;
        })
        .finally(() => {
          this.isLoadingTickets = false;
        });
      if (!addTicket) {
        this.page = 1;
        this.first = 0;
      }
    },
    forceUpdate() {
      this.setItemsPerPage(this.rows);
      this.$forceUpdate();
    },
    onPage(event) {
      this.nextPage(event.page);
      this.active = -1;
      if (
        (event.page === event.pageCount ||
          event.page === event.pageCount - 1 ||
          event.page === event.pageCount - 2) &&
        this.getLength == this.getRange - 100
      ) {
        this.fetchFilteredTickets(true);
      }
    },
    async saveTicket() {
      this.submitted = true;
      this.isLoadingTicket = true;

      const isFormCorrect = await this.v$.newTicket.$validate();
      if (isFormCorrect) {
        // CONVERT DATE TO STRING VALUE
        if (this.newTicket.date == "") {
          this.newTicket.date = "";
        } else {
          this.newTicket.date = Utils.formatDate(this.newTicket.date);
        }

        if (this.isEditing) {
          const old = this.getTickets.find(
            (ticket) => ticket.id == this.newTicket.id
          );
          this.getTickets[this.activeTicketIndex] = this.newTicket;

          this.ticketService
            .updateTicket({
              ticket_id: this.newTicket.id,
              newTicket: this.newTicket,
              oldTicket: old,
            })
            .then(() => {
              this.isLoadingTicket = false;
              this.showAddTicketDialog = false;
            })
            .catch(() => {
              this.isLoadingTicket = false;
            });
        } else {
          this.getTickets.push(this.newTicket);
          this.addTicket({
            ticket: this.newTicket,
          })
            .then(() => {
              this.showAddTicketDialog = false;
              this.showAddTimeEntryDialog = false;
            })
            .finally(() => {
              this.isLoadingTicket = false;
            });
          if (this.currentView === "customers") {
            this.getTicketIds(this.index).forEach((id) => {
              this.selectedTicketID += id + " ";
            });
          }
          this.fetchTickets({
            custs: this.getCustomersFilter(),
            ids: this.filters.id.value,
            status: this.selectedFilterStatus,
            addTicket: false,
            assignees: this.getAssigneeFilter(),
            correls: "cust_name",
            types: this.getTypesFilter(),
            sortBy: this.sort.sortBy,
            sortOrder: this.sort.sortOrder,
          });
        }
      } else {
        this.isLoadingTicket = false;
        return;
      }
    },
    fetchTicketToDisplay(ticket) {
      this.isLoadingTicket = true;
      let rangeStart = this.rangeStart;
      let rangeEnd = this.rangeEnd;

      rangeStart = 1;
      rangeEnd = this.maxExportRows;

      this.fetchTickets({
        custs: [ticket.cust_id],
        ids: ticket.id,
        status: this.selectedFilterStatus,
        addTicket: false,
        assignees: [ticket.assigned_to],
        types: this.getTypesFilter(),
        correls: "cust_name",
        sortBy: this.sort.sortBy,
        sortOrder: this.sort.sortOrder,
      })
        .then((resp) => {
          this.ticketArray = resp.data;
          this.printExportItems = resp.data;
          
          this.totalRecords = resp.data.length;
        })
        .finally(() => {
          this.isLoadingTicket = false;
        });
      this.page = 1;
      this.first = 0;
    },
    initFields({ ticket, currentIndex }) {
      this.activeTicketIndex = currentIndex;
      this.isEditing = true;
      this.newTicketCust = ticket.cust;
      this.setBillable(ticket.type);
      this.newTicket = ticket;
      this.selectedAssignee = ticket.assigned_to;
      this.isEditing = true;
    },
    confirmTime() {
      return new Promise((resolve) => {
        this.$confirm.require({
          message:
            "You have entered more than 12 hours, do you want to proceed?",
          header: "Confirmation",
          icon: "pi pi-exclamation-triangle",
          accept: () => {
            resolve(true);
          },
          reject: () => {
            resolve(false);
          },
        });
      });
    },
    async saveTimeEntryInfo() {
      this.submitted = true;
      this.isLoadingTimeEntry = true;
      const isFormCorrect = await this.v$.billingInfo.$validate();
      if (isFormCorrect) {
        // CONVERT DATE TO STRING VALUE
        this.billingInfo.date = Utils.formatDate(this.billingInfo.date);
        this.postBilling({
          payload: this.billingInfo,
        }).finally(() => {
          this.showAddTimeEntryDialog = false;
          this.isLoadingTimeEntry = false;
        });
      } else {
        this.isLoadingTimeEntry = false;
        return;
      }
    },
    getAssigneeFilter() {
      let assignees = [];
      if (this.filters.assigned_to.value) {
        assignees = Object.values(this.filters.assigned_to.value);
      }
      return assignees;
    },
    getTypesFilter() {
      let types = [];
      if (this.filters.type.value) {
        types = Object.values(this.filters.type.value);
      }
      return types;
    },
    getCustomersFilter() {
      return this.selectedFilterCusts.map((cust) => {
        return cust.cust_id;
      });
    },
    getBillRecords() {
      this.updateCurrentIndex(-1);
      this.activeTicketIndex = this.active;
      this.timeLogHidden = true;
      this.loadingBill = true;
      this.TotalHours = 0;
      this.currentIndex = this.active;
      let isClosed = this.getTickets[this.currentIndex].closed;
      if (isClosed === "Y") {
        this.statusOptions[0].label = "Open";
      } else {
        this.statusOptions[0].label = "Close";
      }
      if (this.currentIndex == null) this.currentIndex = this.active;
      const ticketId = this.getTickets[this.currentIndex].id;
      this.fetchBills({
        ticket: ticketId,
      }).then((response) => {
        this.billing = response;
        for (let i = 0; i < this.billing.bill_items.length; i++) {
          // for proper datatable sort by date, date strings are converted to date objects
          this.billing.bill_items[i].date =
            this.billing.bill_items[i].date != null
              ? new Date(this.billing.bill_items[i].date)
              : "";
          this.TotalHours =
            this.TotalHours + (parseFloat(this.billing.bill_items[i].hours) || 0);
        }
        this.loadingBill = false;
      });
    },
    displayStatus(code) {
      return code == "" || code == "N" ? "Open" : "Closed";
    },
    clearTicketSearchBar() {
      this.selectedFilterId = "";
      this.filters.id.value = "";
      this.fetchFilteredTickets(false);
    },
    clearCustonmerSearchBar() {
      this.filters.cust_name.value = [];
      this.selectedFilterCusts = [];
      this.fetchFilteredTickets(false);
    },
    clearAssignee() {
      this.selectedAssignee = null;
      this.filters.assigned_to.value = [];
      this.selectedFilterAssignees = [];
      this.fetchFilteredTickets(false);
    },
    clearTypes() {
      this.filters.type.value = [];
      this.selectedFilterTypes = [];
      this.fetchFilteredTickets(false);
    },
    clearStatus() {
      this.filters.closed.value = [];
      this.selectedFilterStatus = null;
      this.fetchFilteredTickets(false);
    },
    deleteTicket() {
      this.deleteTicketDialog = true;
    },
    closeDialog() {
      this.showTimeLogDialog = false;
      this.showAddTicketDialog = false;
      this.showAddTimeEntryDialog = false;
    },
    clearFilter() {
      this.filters.assigned_to.value = [];
      if (
        !(this.getCurrentCustTabIndex == this.index && this.getCurrentCustomer)
      ) {
        this.filters.cust_name.value = [];
        this.selectedFilterCusts = [];
      }
      this.filters.type.value = [];
      this.selectedFilterStatus = null;
      this.filters.closed.value = [];
      this.filters.id.value = "";
      this.selectedFilterId = "";
      this.selectedFilterAssignees = [];
      this.selectedFilterTypes = [];
      this.fetchFilteredTickets(false);
    },
    handleFilter(event) {
      this.$emit("update-badge", "ticket_ids", event.filteredValue.length);
    },
    onCloseSelectedTickets() {
      let successStatusChangeIds = [];
      let failesStatusChange = false;
      let oldTickets = [];
      let newTickets = [];
      let ids = [];
      let errorDetail = "";
      this.checkedTickets.forEach((oldTicket) => {
        let newTicket = JSON.parse(JSON.stringify(oldTicket));
        delete newTicket.formated_date;
        newTicket.closed = "Y";
        newTickets.push(newTicket);
        oldTickets.push(oldTicket);
        ids.push(newTicket.id);
      });
      this.batchService
        .updateRecords({
          newRecords: newTickets,
          oldRecords: oldTickets,
          filename: "TICKET",
        })
        .then((response) => {
          response.details.forEach((record) => {
            if (record.status === "success") {
              successStatusChangeIds.push(record.recordId);
            } else {
              failesStatusChange = true;
              errorDetail = record.error;
            }
          });
        })
        .catch((error) => {
          error.response.details.forEach((record) => {
            if (record.status === "success") {
              successStatusChangeIds.push(record.recordId);
            } else {
              failesStatusChange = true;
              errorDetail = record.error;
            }
          });
        })
        .finally(() => {
          this.fetchFilteredTickets(false);
          this.checkedTickets = [];
          if (successStatusChangeIds.length) {
            this.addNotification({
              message: `Successfully closed ticket(s) ${successStatusChangeIds.join(
                ", "
              )}`,
              type: "success",
            });
          }
          if (failesStatusChange) {
            const failedIds = ids.filter(
              (item) => !successStatusChangeIds.includes(item)
            );
            this.addNotification({
              message: `Failed to close ticket(s) ${failedIds.join(
                ", "
              )}. ${errorDetail}`,
              type: "error",
            });
          }
        });
    },
  },
});
</script>

<style lang="scss">
.line-break {
  white-space-collapse: preserve;
}
.split-sales-order-btn {
  height: 37px;
  margin: 5px;
}
</style>
